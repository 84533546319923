import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";

import Layout from "src/components/layout";

class TOC extends React.Component {
  render() {
    // Use to mark new items:
    // <span className="note"><sup>NEW</sup></span>

    return (
      <Layout hasHero={false}>
        <Helmet>
          <body className="no-hero" />
        </Helmet>

        <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>

        <div className="grid grid--subpage gutter">
          <aside className="sidebar">
            <p>&nbsp;</p>
          </aside>

          <div className="content-wrap">
            <article>
              <div className="content content--subpage content--toc">

                <h2>Pages in Progress</h2>

                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li>
                    <Link to="/business">Business</Link>
                    <ul>
                      <li><Link to="/business/business-opportunities/">Business Opportunities</Link> <span className="note">| has curated event below subnan</span></li>
                      <li><Link to="/business/filming-special-events/">Filming/Special Events</Link></li>
                      <li><Link to="/business/finance/">Finance</Link></li>
                      <li><Link to="/business/foreign-trade-zone-ftz/">Foreign Trade Zone</Link></li>
                      <li><Link to="/business/incentives/">Incentives</Link></li>
                      <li><Link to="/business/labor-compliance-program-plas/">Labor Compliance Program / PLAS</Link></li>
                      <li><Link to="/business/permits/">Permits</Link></li>
                      <li><Link to="/business/permits#filming-special-events-permit/filming-special-event-permit-application/">Filming/Special Events Permit Application</Link></li>
                      <li><Link to="/business/permits/#filming-special-events-permit/location-scouting-info">Location Scouting Info</Link></li>
                      <li><Link to="/business/permits/#unmanned-aircraft-systems/unmanned-aircraft-systems-uas-permit-application">Unmanned Aircraft Systems (UAS) Permit Application</Link></li>
                      <li><Link to="/business/pierpass">PierPASS</Link></li>
                      <li><Link to="/business/port-operations-and-facilities/">Port Operations & Facilities</Link> <span className="note">| has Visualizer tables</span></li>
                      <li><Link to="/business/port-statistics/">Port Statistics</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/environment/">Environment</Link> <span className="note">| has hero background video</span>
                    <ul>
                      <li><Link to="/environment/clean-trucks/">Clean Trucks</Link></li>
                      <li><Link to="/environment/electronic-data-submittal-templates/">Electronic Data Submittal Templates</Link></li>
                      <li><Link to="/environment/shore-power/">Shore Power</Link> <span className="note">| has multiple file tables</span></li>
                      <li><Link to="/environment/soils-and-sediments/">Soils & Sediments</Link></li>
                      <li><Link to="/environment/water-and-land/">Water & Land</Link></li>
                      <li><Link to="/environment/wildlife/">Wildlife</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/community">Community</Link>
                    <ul>
                      <li><Link to="/community/community-grants-program-main/">Community Grants Program (main)</Link> <span className="note">| cards deep-link to subpages</span></li>
                      <li><Link to="/community/community-grants-program/">Community Grants Program</Link></li>
                      <li><Link to="/community/sponsorships/">Community Sponsorships</Link></li>
                      <li><Link to="/community/education/">Education</Link></li>
                      <li><Link to="/community/education-resources-students/">Education Resources: Students</Link></li>
                      <li><Link to="/community/education-resources-teachers/">Education Resources: Teachers</Link></li>
                      <li><Link to="/community/harbor-tours/">Harbor Tours</Link></li>
                      <li><Link to="/community/harbor-tours/#harbor-tours-info-and-registration">Harbor Tours Info and Registration</Link> <span className="note">| has gallery</span></li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/commission/">Commission</Link>
                    <ul>
                      <li><Link to="/commission/board-meetings">Board Meetings</Link></li>
                      <li><Link to="/commission/ticket-reporting-policy#view-tickets-by-year">Tickets By Year</Link> <span className="note">| has accordion</span></li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/port-info">Port Info</Link>
                    <ul>
                      <li><Link to="/port-info/calendar-of-events/">Calendar of Events</Link></li>
                      <li><Link to="/port-info/event-details/?e=59">Calendar of Events: Event Details</Link></li>
                      <li><Link to="/port-info/contact-us/">Contact Us</Link></li>
                      <li><Link to="/port-info/faqs/">FAQS</Link></li>
                      <li><Link to="/port-info/history/">History</Link></li>
                      <li><Link to="/port-info/mission-vision/">Mission & Vision</Link></li>
                      <li><Link to="/port-info/news-and-press/">News & Press</Link></li>
                      <li><Link to="/port-info/news-archive/">News Archive</Link></li>
                      <li><Link to="/port-info/privacy/">Privacy</Link></li>
                      <li><Link to="/port-info/projects/">Projects</Link></li>
                      <li><Link to="/port-info/security/">Security</Link></li>
                      <li><Link to="/port-info/map/">Map <span className="note">| work in progress (version 1)</span></Link></li>
                    </ul>
                  </li>
                  <li><Link to="/documents">Documents</Link></li>
                  <li><Link to="/jobs/">Jobs</Link></li>
                  <li>
                    Resources
                    <ul>
                      <li><Link to="/resources/cargo-owners/">Cargo Owners</Link></li>
                      <li><Link to="/resources/ocean-carriers/">Ocean Carriers</Link></li>
                      <li><Link to="/resources/tenants/">Tenants</Link></li>
                      <li><Link to="/resources/truckers/">Truckers</Link></li>
                    </ul>
                  </li>
                  <li>Search Results <span className="note">| viewed by conducting search from header</span></li>
                </ul>
              </div>
            </article>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TOC;
